import React from 'react';
import classNames from 'classnames';
import { useTranslation } from '@wix/yoshi-flow-editor';
import {
  getPostCover,
  getTPASettingsIsCommentsEnabled,
  resolveId,
} from '@wix/communities-blog-client-common';
import ContentExcerpt from '@app/external/common/components/content-excerpt';
import { CounterCompact } from '@app/external/common/components/counter-compact';
import { CommentIcon } from '@app/external/common/components/icons/comment-icon';
import { ViewCountIcon } from '@app/external/common/components/icons/view-count-icon';
import { LikeButtonWithCount } from '@app/external/common/components/like-button-with-count';
import { useSelector } from '@app/external/common/components/runtime-context';
import { SkeletonRenderer } from '@app/external/common/components/skeleton-renderer';
import { getIsMetadataLoaded } from '@app/external/common/store/is-loaded/is-loaded-selectors';
import {
  getCommentCount,
  getViewCount,
} from '@app/external/common/store/post-counters/post-counters-selectors';
import { type NormalizedPost } from '@app/external/common/types';
import RecentPostListItemCover from '@app/external/recent-posts/components/recent-post-list-item-cover';
import styles from './post-page-related-posts-section.scss';

type Props = {
  post: NormalizedPost;
};

export const PostPageRelatedPostsSectionItem = ({ post }: Props) => {
  const hasCoverImage = getPostCover(post)?.shouldRender;
  const { t } = useTranslation();
  const postId = resolveId(post);
  const viewCount: number = useSelector((state) => getViewCount(state, postId));
  const totalComments: number = useSelector((state) => getCommentCount(state, postId));
  const isMetadataLoaded = useSelector(getIsMetadataLoaded);
  const isCommentsEnabledInTpaSettings = useSelector(getTPASettingsIsCommentsEnabled);

  const showCommentCount =
    (totalComments > 0 || !post.isCommentsDisabled) && isCommentsEnabledInTpaSettings
      ? true
      : false;

  return (
    <article className={styles.itemRoot} data-hook="recent-post-list-item">
      <div className={styles.itemOverlay}>
        {hasCoverImage && <RecentPostListItemCover post={post} />}
        <div className={styles.itemContent}>
          <header data-hook="recent-post__title">
            <a className={styles.itemTitle} href={post.link!}>
              {post.title}
            </a>
          </header>

          {!hasCoverImage && (
            <main data-hook="recent-post__description">
              <div className={styles.itemExcerpt}>
                <ContentExcerpt post={post} lineCount={4} maxLength={200} />
              </div>
            </main>
          )}

          <footer data-hook="post-footer" className={styles.itemFooterWrapper}>
            <div className={styles.itemFooterLeftButtons}>
              <div
                className={classNames(styles.itemStatsContainer, 'post-stats')}
                data-hook="post-stats"
              >
                <SkeletonRenderer
                  className={styles.itemViewCount}
                  isLoaded={isMetadataLoaded}
                  width={30}
                >
                  <CounterCompact dataHook="view-count" count={viewCount}>
                    <ViewCountIcon
                      aria-label={t('view-count.label', {
                        count: viewCount,
                      })}
                    />
                  </CounterCompact>
                </SkeletonRenderer>

                {showCommentCount && (
                  <SkeletonRenderer
                    className={styles.itemCommentCount}
                    isLoaded={isMetadataLoaded}
                    width={30}
                  >
                    <CounterCompact
                      dataHook="comment-count"
                      ariaHidden={true}
                      count={totalComments}
                      showZero
                    >
                      <CommentIcon
                        title={t('comment-count.label', {
                          count: totalComments,
                        })}
                      />
                    </CounterCompact>
                  </SkeletonRenderer>
                )}
              </div>
            </div>

            <SkeletonRenderer
              className={styles.itemLikeButton}
              isLoaded={isMetadataLoaded}
              width={30}
            >
              <LikeButtonWithCount key={postId} postId={postId!} />
            </SkeletonRenderer>
          </footer>
        </div>
      </div>
    </article>
  );
};
