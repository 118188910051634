import '@wix/ricos/css/plugin-giphy-viewer.global.css';
import '@wix/ricos/css/plugin-image-viewer.global.css';
import '@wix/ricos/css/plugin-link-viewer.global.css';
import '@wix/ricos/css/plugin-mentions-viewer.global.css';
import '@wix/ricos/css/plugin-video-viewer.global.css';

import React, { Component, Suspense, useCallback, useContext, useMemo } from 'react';
import { type TPAComponentsConfig } from 'wix-ui-tpa';
import { TPAComponentsProvider } from 'wix-ui-tpa/cssVars';
import {
  type ContextFields,
  WixComments,
  WixCommentsApiProvider,
  type Configuration,
  type DeepLinkConfig,
  type FormatRelativeTime,
  type PaginationConfig,
  type PluginConfigRoot,
  type RatingsConfig,
  type ReactionsConfig,
} from '@wix/comments-ooi-client';
import { WixCommentsTranslationsProvider } from '@wix/comments-ooi-translations-provider';
import {
  EMOJI_TYPE,
  GIPHY_TYPE,
  IMAGE_TYPE,
  LINK_TYPE,
  MENTION_TYPE,
  pluginEmoji,
  pluginEmojiViewer,
  pluginGiphy,
  pluginGiphyViewer,
  pluginImage,
  pluginImageViewer,
  pluginLink,
  pluginLinkViewer,
  pluginMentions,
  pluginMentionsViewer,
  pluginTextColor,
  pluginTextColorViewer,
  pluginVideo,
  pluginVideoViewer,
  TEXT_COLOR_TYPE,
  VIDEO_TYPE,
} from '@wix/ricos';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';
import {
  formatDate,
  getTPASettingsIsMandatoryRatingsEnabled,
  getTPASettingsIsRatingsEnabled,
  resolveLegacyId,
  type DeepPartial,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_WIX_COMMENTS_USE_REACTIONS } from '@wix/communities-blog-experiments';
import AnimatedLoader from '@app/external/common/components/animated-loader';
import useOnScreen from '@app/external/common/hooks/use-on-screen';
import { usePostPageSettings } from '@app/external/common/hooks/use-post-page-settings';
import { type NormalizedPost } from '@app/external/common/types';
import { RootPropsContext } from '../../../../common/components/root-props/root-props-context';
import { useActions, useSelector } from '../../../../common/components/runtime-context';
import {
  getCommentId,
  getIsMobile,
  getLanguage,
  getUrl,
  isSeo as getIsSeo,
  isSite as getIsSite,
} from '../../../../common/store/basic-params/basic-params-selectors';
import styles from './post-page-comments-section.scss';

type Props = {
  post: NormalizedPost;
};

const PostComments: React.FC<Props> = ({ post }) => {
  const url = useSelector(getUrl);
  const { isRTL } = useEnvironment();
  const isMobile = useSelector(getIsMobile);
  const isSledRun = url.includes('viewerPlatformOverrides');
  const actions = useActions();
  const commentId = useSelector(getCommentId);
  const isSite = useSelector(getIsSite);
  const language = useSelector(getLanguage);
  const isRatingsEnabled = useSelector(getTPASettingsIsRatingsEnabled);
  const isRatingsMandatory = useSelector(getTPASettingsIsMandatoryRatingsEnabled);

  const { experiments } = useExperiments();

  const isWixCommentsUseReactionsEnabled = experiments.enabled(
    EXPERIMENT_WIX_COMMENTS_USE_REACTIONS,
  );

  const pluginConfig: PluginConfigRoot = useMemo(
    () => ({
      [MENTION_TYPE]: {
        editorModule: pluginMentions,
        viewerModule: pluginMentionsViewer,
        editorConfig: {
          getMentions: actions.fetchMentionsPromisified,
          supportWhitespace: false,
        },
      },

      [LINK_TYPE]: { editorModule: pluginLink, viewerModule: pluginLinkViewer },

      [EMOJI_TYPE]: {
        editorModule: pluginEmoji,
        viewerModule: pluginEmojiViewer,
      },

      [IMAGE_TYPE]: {
        editorModule: pluginImage,
        viewerModule: pluginImageViewer,
      },

      [GIPHY_TYPE]: {
        editorModule: pluginGiphy,
        viewerModule: pluginGiphyViewer,
      },

      [VIDEO_TYPE]: {
        editorModule: pluginVideo,
        viewerModule: pluginVideoViewer,
      },

      [TEXT_COLOR_TYPE]: {
        editorModule: pluginTextColor,
        viewerModule: pluginTextColorViewer,
      },
    }),
    [actions.fetchMentionsPromisified],
  );

  const tpaProviderProps: TPAComponentsConfig = useMemo(
    () => ({
      mobile: isMobile,
      rtl: isRTL,
    }),
    [isMobile, isRTL],
  );

  const deepLink: DeepLinkConfig = useMemo(
    () => ({
      commentId,
      generateLink: (id) => {
        if (isSite) {
          const commentUrl = new URL(window.location.href);
          commentUrl.searchParams.set('commentId', id);
          return commentUrl.toString();
        }

        return '';
      },
    }),
    [commentId, isSite],
  );

  const resourceId = resolveLegacyId(post)!;

  const ctxFields: ContextFields = useMemo(
    () => ({
      contextId: resourceId,
      contextType: 'postId',
    }),
    [resourceId],
  );

  const reactions: ReactionsConfig = useMemo(
    () =>
      isWixCommentsUseReactionsEnabled
        ? {
            commentReactions: { type: 'emotions' },
            replyReactions: { type: 'emotions' },
          }
        : {},
    [isWixCommentsUseReactionsEnabled],
  );

  const ratings: RatingsConfig | undefined = useMemo(
    () => (isRatingsEnabled ? { isRequired: isRatingsMandatory } : undefined),
    [isRatingsEnabled, isRatingsMandatory],
  );

  const formatRelativeTime: FormatRelativeTime = useCallback(
    (time) => formatDate({ date: time as any, lng: language }),
    [language],
  );
  const rootProps = useContext(RootPropsContext);

  return (
    <section className={styles.root}>
      <div className={styles.contentWidth}>
        <WixCommentsApiProvider {...rootProps}>
          <WixCommentsTranslationsProvider>
            <TPAComponentsProvider value={tpaProviderProps}>
              <WixComments
                hideZeroCommentsEmptyState
                screenshotMode={isSledRun}
                pluginConfig={pluginConfig}
                resourceId={resourceId}
                deepLink={deepLink}
                ctxFields={ctxFields}
                pagination={PAGINATION_CONFIG}
                formatRelativeTime={formatRelativeTime}
                isLocked={post.isCommentsDisabled}
                reactions={reactions}
                a11yOptions={ALLY_OPTIONS}
                contentTruncationLimit={isMobile ? 80 : 120}
                ratings={ratings}
                // @ts-expect-error
                useRicosNext
              />
            </TPAComponentsProvider>
          </WixCommentsTranslationsProvider>
        </WixCommentsApiProvider>
      </div>
    </section>
  );
};

const ALLY_OPTIONS: NonNullable<Configuration['a11yOptions']> = { headerRank: 'h2' };

const PAGINATION_CONFIG: DeepPartial<PaginationConfig> = {
  replyShowMoreLimit: 8,
  initialPage: {
    commentLimit: 20,
    replyLimit: 0,
  },
  pagination: {
    commentLimit: 20,
    replyLimit: 0,
  },
  maxPagesBeforeDrop: {
    commentPages: 2,
    replyPages: 4,
  },
};

// Internal comments error handler should catch errors, but there still can be cases where error bubbles up because of bundling issues etc..
class PostCommentsWithErrorBoundary extends Component<Props> {
  state = { isInErrorState: false };

  componentDidCatch(error: Error) {
    this.setState({ isInErrorState: true });
    console.error(error);
  }

  render() {
    if (this.state.isInErrorState) {
      return null;
    }

    return <PostComments {...this.props} />;
  }
}

// #region Comments
type CommentsSectionProps = {
  post: NormalizedPost;
  forceComments: boolean;
  commentsRef: React.RefObject<HTMLDivElement>;
};

const PostPageCommentsSection: React.FC<CommentsSectionProps> = (props) => {
  const post = props.post;
  const isSeo = useSelector(getIsSeo);
  const commentId = useSelector(getCommentId);
  const { showComments } = usePostPageSettings({ post });
  const { isSSR, isEditor } = useEnvironment();
  const canSeePost = post?.canSeePaidContent !== false;
  const renderComments = Boolean(canSeePost && showComments && post?.id);
  const { isOnScreen, ref } = useOnScreen(undefined, isEditor);
  const actions = useActions();

  if (!renderComments) {
    return null;
  }

  if (isSeo) {
    return (
      <div ref={props.commentsRef}>
        <PostCommentsWithErrorBoundary post={post} />
      </div>
    );
  }

  if (!isSSR && commentId) {
    actions.initWixCommentsController();

    return (
      <div ref={props.commentsRef}>
        <Suspense fallback={<AnimatedLoader isLoading />}>
          <PostCommentsWithErrorBoundary post={post} />
        </Suspense>
      </div>
    );
  }

  if (!isOnScreen && !props.forceComments) {
    return <div ref={ref as any} />;
  }

  actions.initWixCommentsController();

  return (
    <div ref={props.commentsRef}>
      <Suspense fallback={<AnimatedLoader isLoading />}>
        <PostCommentsWithErrorBoundary post={post} />
      </Suspense>
    </div>
  );
};

export default PostPageCommentsSection;
